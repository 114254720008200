import React, { useState } from 'react'
import { navigate } from '@reach/router'
import api from '../api.js'
import './css/Admin.css'

const handleSubmit = (event, contact, setErrorPost) => {
  event.preventDefault()
  api
    .updateContact(contact.id, contact)
    .then(() => navigate('/admin/contact'))
    .then(() => window.location.reload())
    .catch((error) => {
      setErrorPost('Oopsie, une erreur est survenue !')
      console.log(error)
    })
}

const handleChange = (event, setContact, contact) => {
  const key = event.target.name
  setContact({
    ...contact,
    [key]: event.target.value,
  })
}

const AdminContactItemForm = ({ data, isSocialNetwork }) => {
  const [contact, setContact] = useState({
    id: data.id || '',
    name: data.name || '',
    details: data.details || '',
    type: data.type || '',
  })

  const [errorPost, setErrorPost] = useState('')

  return (
    <form
      className="contactForm"
      onSubmit={(e) => handleSubmit(e, contact, setErrorPost)}
    >
      {!isSocialNetwork && (
        <Input
          type="text"
          label="Titre"
          name="name"
          value={contact.name}
          setContact={setContact}
          contact={contact}
        />
      )}
      <Input
        type={isSocialNetwork ? 'text' : 'textarea'}
        label={isSocialNetwork ? contact.name : 'Coordonnées'}
        name="details"
        value={contact.details}
        setContact={setContact}
        contact={contact}
      />
      <input className="submit" type="submit" value="Enregistrer" />
      <div className="errorPost">{errorPost}</div>
    </form>
  )
}

export const AdminEditContact = ({ contactData }) => {
  const sections = {
    adresses: contactData?.filter(({ type }) => type === 'adresse'),
    contacts: contactData?.filter(({ type }) => type === 'contact'),
    réseaux: contactData?.filter(({ type }) => type === 'reseau'),
  }

  return (
    <div style={{ padding: '100px' }}>
      {Object.entries(sections).map(([sectionName, items]) => (
        <div key={sectionName} style={{ marginBottom: '20px' }}>
          <div className="formTitle yellow capitalize">{sectionName} :</div>
          <div className="contactForms">
            {items.map((data) => (
              <AdminContactItemForm
                key={data.name}
                data={data}
                isSocialNetwork={sectionName === 'réseaux'}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

const TextArea = ({ ...props }) => <textarea type="textarea" {...props} />
const TextInput = ({ ...props }) => <input type="text" {...props} />

const Input = ({ label, setContact, contact, type, ...props }) => {
  const InputType = (type === 'textarea' && TextArea) || TextInput
  return (
    <label style={{ textTransform: 'capitalize' }}>
      {label} :<br />
      <InputType
        onChange={(e) => handleChange(e, setContact, contact)}
        {...props}
      />
    </label>
  )
}
