import React from 'react'
import Nav from './Nav.js'
import Facebook from './img/fb.png'
import Instagram from './img/instagram.png'
import Linkedin from './img/linkedin.png'
import './css/Contact.css'
import store from '../store.js'

const Contact = () => {
  const state = store.getState()
  const contact = state?.contact?.contact

  const addresses = contact?.filter(({ type }) => type === 'adresse')
  const contacts = contact?.filter(({ type }) => type === 'contact')
  const networks = contact?.filter(({ type }) => type === 'reseau')

  const get_network_url = (name) =>
    networks?.find((network) => network.name === name)?.details

  return (
    <div>
      <Nav />
      <div className="spacer" />
      <div className="Contact">
        <div className="wrapper">
          <h2 className="green">Contactez l'Atelier !</h2>

          <div className="categories currentText">
            <div className="category">
              <h4>Adresses</h4>
              <div className="items">
                {addresses?.map((address) => (
                  <div key={address.name} className="item">
                    <div className="name">{address.name}</div>
                    <div>{address.details}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className="category">
              <h4>Contacts</h4>
              <div className="items">
                {contacts?.map((contact, index) => (
                  <div key={contact.name} className="item contact">
                    <div className="name">{contact.name}</div>
                    <div>{contact.details}</div>
                    {!index && (
                      <div className="reseaux">
                        <a
                          href={get_network_url('facebook')}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Facebook} alt="facebook" />
                        </a>
                        <a
                          href={get_network_url('instagram')}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Instagram} alt="instagram" />
                        </a>
                        <a
                          href={get_network_url('linkedin')}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Linkedin} alt="linkedin" />
                        </a>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="iframes">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20998.894164541995!2d2.416454526476647!3d48.86084622439984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66dbf6c1ec837%3A0xbfe9c002e32ff463!2sOpale%20-%20Plateau%20Urbain!5e0!3m2!1sen!2sfr!4v1669058754775!5m2!1sen!2sfr"
            referrerPolicy="no-referrer-when-downgrade"
            title="iframe-google-maps-montreuil"
            allowFullScreen
            frameBorder="0"
            loading="lazy"
          />
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.5894828446126!2d-1.6259927009190935!3d48.137439612978135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x480edeb072be4fd1%3A0x51159544b761f873!2sSystemGie!5e0!3m2!1sen!2sfr!4v1669058969742!5m2!1sen!2sfr"
            referrerPolicy="no-referrer-when-downgrade"
            title="iframe-google-maps-rennes"
            allowFullScreen
            frameBorder="0"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default Contact
